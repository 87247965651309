<template>
  <div>
    <Header />
    <div class="page-content">
      <section class="services top-padding-zero" data-aos="zoom-in">
        <div class="container">
          <h2 class="sectio-title">{{ $t("legal.legal") }}</h2>
          <h3 class="card-title text-left">
            {{ $t("legal.legal-title-1") }}
          </h3>
          <div class="desc text-left full-size">
            {{ $t("legal.legal-desc-1") }}
          </div>
          <h3 class="card-title text-left">{{ $t("legal.legal-title-2") }}</h3>
          <div class="desc text-left full-size">
            {{ $t("legal.legal-desc-2") }}
          </div>
          <h3 class="card-title text-left">{{ $t("legal.legal-title-3") }}</h3>
          <div class="desc text-left full-size">
            {{ $t("legal.legal-desc-3") }}
          </div>
          <h3 class="card-title text-left">{{ $t("legal.legal-title-4") }}</h3>
          <div class="desc text-left full-size">
            {{ $t("legal.legal-desc-4") }}
          </div>
          <h3 class="card-title text-left">
            {{ $t("legal.legal-title-5") }}
          </h3>
          <div class="desc text-left full-size">
            {{ $t("legal.legal-desc-5") }}
          </div>
        </div>
      </section>
    </div>
    <Footer />
  </div>
</template>

<script>
import {
  BButton,
  BImg,
  BNavbarNav,
  BNavbarToggle,
  BNavbar,
  BCollapse,
  BRow,
  BCol,
  BCard,
  BCardText
} from "bootstrap-vue";
import store from "@/store/index";
import Locale from "@/layouts/components/Locale.vue";
import Contact from "@/assets/images/pages/Contact.svg";
import Logo from "@/assets/rashiCoin/logo.svg";
import Header from "./../Header.vue";
import Footer from "./../Footer.vue";
export default {
  components: {
    BButton,
    BImg,
    BNavbarNav,
    BNavbarToggle,
    BNavbar,
    BCollapse,
    Locale,
    BRow,
    BCol,
    BCard,
    BCardText,
    Contact,
    Logo,
    Header,
    Footer
  },
  data() {
    return {
      isActive: 1,
      lang: this.$route.params.lang
    };
  },
  mounted() {},
  methods: {
    changeTab(tab) {
      this.isActive = tab;

      if (tab === 2) {
        document.querySelector(".tab-slider--tabs").classList.add("slide");
      } else {
        document.querySelector(".tab-slider--tabs").classList.remove("slide");
      }
    },
    openPage(props) {
      this.$router.push({
        name: props,
        params: { lang: this.lang || undefined }
      });
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        return this.downImg;
      }
      return this.downImg;
    }
  }
};
</script>

<style lang="scss">
@import "../../assets/landing/css/style.scss";
@import "@core/scss/vue/pages/page-misc.scss";
</style>
<style lang="scss" scoped>
.page-content {
  min-height: 90vh;
  padding-top: 0px;
}
.cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0px 90px;
}
.services .card h3.without-img {
  margin-top: 0px;
}
.full-size {
  width: 100%;
}
.desc.text-left.full-size {
  margin-bottom: 25px;
}
</style>
